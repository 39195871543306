import { Head } from '@/components/Head';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes } from 'react-router-dom';

interface Auth0LoginProps {
  signup?: boolean;
}

const Auth0Login: React.FC<Auth0LoginProps> = ({ signup = false }) => {
  const { loginWithRedirect } = useAuth0();
  const screenHint = signup ? 'signup' : 'login';
  return (
    <>
      <Head description="EmbedOps" />
      {
        void loginWithRedirect({
          redirectUri: `${window.location.origin}/app`,
          appState: {
            returnTo: window.location.pathname,
          },
          screen_hint: screenHint,
        })
      }
    </>
  );
};

export const NonProtectedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/signup" element={<Auth0Login signup />} />
      <Route path="*" element={<Auth0Login />} />
    </Routes>
  );
};
