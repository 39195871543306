import { ClipboardIcon } from '@heroicons/react/outline';

import { useNotificationStore } from '@/hooks/useNotificationStore';
import { copyToClipboard } from '@/utils/text';

/**
 * A simple wrapper that when clicked will copy the children to the clipboard.
 * This element is also tabbable (using tab-key to navigate will focus this element),
 * meaning it's also possible to tab to it and click the 'Enter' key.
 */
export const TextWithCopyButton: React.FC<{ children: string }> = ({ children }) => {
  const { addToast } = useNotificationStore();

  const handleCopy = (): void => {
    copyToClipboard(children);
    addToast({ title: 'Copied' });
  };

  return (
    <button
      className="hover:underline cursor-pointer"
      onClick={handleCopy}
      onKeyPress={(event) => (event.key === 'Enter' ? copyToClipboard(children) : undefined)}
    >
      <div>
        {children}
        <ClipboardIcon className="inline text-gray-400 group-hover:text-gray-300 h-6 w-6 ml-2" />
      </div>
    </button>
  );
};

export const CodeTextWithCopyButton: React.FC<{ children: string }> = ({ children }) => {
  const { addToast } = useNotificationStore();

  return (
    <div className="relative group">
      <button
        onClick={() => {
          copyToClipboard(children);
          addToast({ title: 'Copied' });
        }}
        onKeyPress={(event) => (event.key === 'Enter' ? copyToClipboard(children) : undefined)}
        className="w-full text-left"
      >
        <pre className="border text-neutral-800 border-gray-400 px-4 py-2 bg-gray-50 font-mono text-sm hover:text-yellow-800 active:text-dojoyellow whitespace-pre-wrap break-words overflow-x-auto">
          {children}
        </pre>
        <span className="absolute right-2 top-2 text-dojoyellow hover:underline active:text-yellow-800 opacity-30 group-hover:opacity-100 transition-opacity">
          Copy
        </span>
      </button>
    </div>
  );
};
