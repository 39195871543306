import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

import { Head } from '@/components/Head';
import { lazyImport } from '@/utils/lazyImport';

import { NonProtectedRoutes } from '@/routes/NonProtectedRoutes';

import './Landing.css';
const { ProtectedRoutes } = lazyImport(
  () => import('../../../routes/ProtectedRoutes'),
  'ProtectedRoutes'
);

export const Landing: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { isLoading, isAuthenticated } = useAuth0();

  // capture auth0 error due to trial expiration and convey it to the user
  if (
    searchParams.get('error') === 'access_denied' &&
    searchParams.get('error_description') === 'trial_expiration'
  ) {
    return <h2>Trial Expired</h2>;
  }

  if (isLoading) {
    return (
      <>
        <Head description="EmbedOps" />
        <div className="min-h-screen p-10 flex items-center justify-center landing-container landing-featured-image">
          <div className="p-6 md:flex md:flex-col md:justify-center">
            <div className="landing-logo-image" data-testid="landing-logo" />
            <h2 className="mb-4 landing-title center text-white">Loading EmbedOps...</h2>
          </div>
        </div>
      </>
    );
  }

  if (isAuthenticated) {
    return <ProtectedRoutes />;
  } else {
    return <NonProtectedRoutes />;
  }
};
