import { axios } from '@/lib/axios';
import {
  CiOAuthToken,
  CiRun,
  CI_PROVIDERS,
  Group,
  GroupToProject,
  GroupToUser,
  JobDef,
  JobTemplate,
  Metric,
  Org,
  OrgApiKey,
  Permission,
  Project,
  Release,
  ReleaseFile,
  Repo,
  RepoApiKey,
  RepoBranch,
  RepoPipelineConfig,
  RepoPipelineJob,
  REPO_TYPE,
  STAGE,
  User,
  UserCiOauthTokens,
  Fleet,
  FleetWithDetail,
  FleetDevice,
  CiRunSourceType,
  HILCIRunsQuery,
  HILCIRun,
  CiRunWithMetricAndHilRun,
  SymbolDataPoint,
  ActiveUsersData,
  OrgWithTier,
  Template,
} from '@/types';
import { HIL_PIPELINE_RECURRENCE_FILTER } from '@/utils/constants';

export const getOrgs = (): Promise<OrgWithTier[]> => {
  return axios.get('/orgs');
};

type CreateOrgBody = {
  name: string;
};

type CreateOrgOptions = {
  data: CreateOrgBody;
};

export const createOrg = ({ data }: CreateOrgOptions): Promise<Org> => axios.post('/orgs', data);

export type GetOrgOptions = {
  orgId: Org['id'];
};

export const getOrg = ({ orgId }: GetOrgOptions): Promise<Org> => {
  return axios.get(`/orgs/${orgId}`);
};

type DeleteOrgOptions = {
  org: Org;
};

export const deleteOrg = ({ org }: DeleteOrgOptions): Promise<void> => {
  return axios.delete(`/orgs/${org.id}`);
};

type CreateGroupBody = {
  name: string;
  orgPerm: Permission;
};

type CreateGroupOptions = {
  orgId: Org['id'];
  data: CreateGroupBody;
};

export const createGroup = ({ data, orgId }: CreateGroupOptions): Promise<Group> =>
  axios.post(`/orgs/${orgId}/groups`, data);

export type GetGroupsForOrgOptions = {
  orgId: Org['id'];
};

export const getGroupsForOrg = ({ orgId }: GetGroupsForOrgOptions): Promise<Group[]> => {
  return axios.get(`/orgs/${orgId}/groups`);
};

export type GetProjectsOptions = {
  orgId: Org['id'];
};

export const getProjectsForOrg = ({ orgId }: GetProjectsOptions): Promise<Project[]> => {
  return axios.get(`/orgs/${orgId}/projects`);
};

type CreateProjectBody = {
  name: string;
};

type CreateProjectOptions = {
  orgId: Org['id'];
  data: CreateProjectBody;
};

export const createProject = ({ data, orgId }: CreateProjectOptions): Promise<Project> =>
  axios.post(`/orgs/${orgId}/projects`, data);

type CreateFleetBody = {
  fleetId: string;
  projectId?: Project['id'];
};

type CreateFleetOptions = {
  orgId: Org['id'];
  data: CreateFleetBody;
};

export const createFleet = ({ data, orgId }: CreateFleetOptions): Promise<Fleet> =>
  axios.post(`/orgs/${orgId}/fleets`, data);

export type GetFleetsForOrgOptions = {
  orgId: Org['id'];
};

export const getFleetsForOrg = ({ orgId }: GetFleetsForOrgOptions): Promise<FleetWithDetail[]> => {
  return axios.get(`/orgs/${orgId}/fleets`);
};

type DeleteGroupFromOrgOptions = {
  org: Org;
  group: Group;
};

export const deleteGroupFromOrg = ({ org, group }: DeleteGroupFromOrgOptions): Promise<void> => {
  return axios.delete(`/orgs/${org.id}/groups/${group.id}`);
};

export type InviteUserToGroupOptions = {
  data: {
    email: string;
    userPerm: Permission;
  };
  groupId: Group['id'];
  // orgId is only gathered so the mutation can update it's store.  The api endpoint
  // doesn't need it.
  orgId: Org['id'];
};

export const inviteUserToGroup = ({ data, groupId }: InviteUserToGroupOptions): Promise<User> => {
  return axios.post(`/groups/${groupId}/invite-user`, data);
};

export type EditUserInGroupOptions = {
  data: {
    userPerm: Permission;
  };
  groupId: Group['id'];
  // orgId is only gathered so the mutation can update it's store.  The api endpoint
  // doesn't need it.
  orgId: Org['id'];
  userId: User['id'];
};

export const editUserInGroup = ({
  data,
  groupId,
  userId,
}: EditUserInGroupOptions): Promise<{ user: User }> => {
  return axios.put(`/groups/${groupId}/users/${userId}`, data);
};

export type DeleteUserFromGroupOptions = {
  group: Group;
  userId: GroupToUser['user']['id'];
};

export const deleteUserFromGroup = ({
  group,
  userId,
}: DeleteUserFromGroupOptions): Promise<void> => {
  return axios.delete(`/groups/${group.id}/users/${userId}`);
};

export type AddProjectToGroupOptions = {
  data: {
    projectId: Project['id'];
    projectPerm: Permission;
  };
  groupId: Group['id'];
  // orgId is only gathered so the mutation can update it's store.  The api endpoint
  // doesn't need it.
  orgId: Org['id'];
};

export const addProjectToGroup = ({ data, groupId }: AddProjectToGroupOptions): Promise<void> => {
  return axios.post(`/groups/${groupId}/projects`, data);
};

export type EditProjectInGroupOptions = {
  data: {
    projectPerm: Permission;
  };
  groupId: Group['id'];
  // orgId is only gathered so the mutation can update it's store.  The api endpoint
  // doesn't need it.
  orgId: Org['id'];
  projectId: Project['id'];
};

export const editProjectInGroup = ({
  data,
  groupId,
  projectId,
}: EditProjectInGroupOptions): Promise<void> => {
  return axios.put(`/groups/${groupId}/projects/${projectId}`, data);
};

export type DeleteProjectFromGroupOptions = {
  group: Group;
  projectId: GroupToProject['project']['id'];
};

export const deleteProjectFromGroup = ({
  group,
  projectId,
}: DeleteProjectFromGroupOptions): Promise<void> => {
  return axios.delete(`/groups/${group.id}/projects/${projectId}`);
};

export type GetProjectOptions = {
  projectId: Project['id'];
};

export const getProject = ({ projectId }: GetProjectOptions): Promise<Project> => {
  return axios.get(`/projects/${projectId}`);
};

export const getProjectSafe = (projectId: string | undefined): Promise<Project | undefined> => {
  if (projectId) {
    return axios.get(`/projects/${projectId}`);
  }

  return Promise.resolve(undefined);
};

export type DeleteProjectOptions = {
  project: Project;
};

export const deleteProject = ({ project }: DeleteProjectOptions): Promise<Project> => {
  return axios.delete(`/projects/${project.id}`);
};

export type GetProjectRepoDefaultBranchesOptions = {
  projectId: Project['id'];
};

export const getProjectRepoDefaultBranches = ({
  projectId,
}: GetProjectRepoDefaultBranchesOptions): Promise<RepoBranch[]> => {
  return axios.get(`/projects/${projectId}/repo-default-branches`);
};

export type GetLatestCiRunsOptions = {
  orgId: Org['id'];
  projectId: Project['id'];
  repoId: Repo['id'];
  branch?: string;
  count: number;
};

export const getLatestCiRuns = ({
  orgId,
  projectId,
  repoId,
  branch,
  count,
}: GetLatestCiRunsOptions): Promise<CiRun[]> => {
  const params = {
    branch,
    count,
  };
  return axios.get(`/orgs/${orgId}/projects/${projectId}/repos/${repoId}/ci-runs`, { params });
};

export type GetReleasesOptions = {
  orgId: Org['id'];
  projectId: Project['id'];
};

export const getReleases = ({ orgId, projectId }: GetReleasesOptions): Promise<Release[]> => {
  return axios.get(`/orgs/${orgId}/projects/${projectId}/releases`);
};

export type GetReleaseOptions = {
  orgId: Org['id'];
  projectId: Project['id'];
  releaseId: Release['id'];
};

export const getRelease = ({
  orgId,
  projectId,
  releaseId,
}: GetReleaseOptions): Promise<Release & { files: ReleaseFile[] }> => {
  return axios.get(`/orgs/${orgId}/projects/${projectId}/releases/${releaseId}`);
};

export type GetCiRunsByLatestPipelineOptions = {
  orgId: Org['id'];
  projectId: Project['id'];
  repoId: Repo['id'];
  branch: string;
};

export const getCiRunsByLatestPipeline = ({
  orgId,
  projectId,
  repoId,
  branch,
}: GetCiRunsByLatestPipelineOptions): Promise<CiRunWithMetricAndHilRun[]> => {
  const params = { branch };
  return axios.get(
    `/orgs/${orgId}/projects/${projectId}/repos/${repoId}/ci-runs/by-latest-pipeline`,
    { params }
  );
};

export type GetRepoBranchOptions = {
  repoId: Repo['id'];
  sourceType?: CiRunSourceType;
  defaultBranchOnly?: boolean;
  createdAt?: {
    begin?: ISOString;
  };
  fields?: Array<keyof RepoBranch>;
};

export const getRepoBranches = ({
  repoId,
  sourceType,
  defaultBranchOnly,
  createdAt,
  fields,
}: GetRepoBranchOptions): Promise<RepoBranch[]> => {
  const params: Record<string, any> = { defaultBranchOnly, sourceType, fields };
  if (createdAt?.begin) {
    params['createdAt[begin]'] = createdAt.begin;
  }
  return axios.get(`/repos/${repoId}/branches`, { params });
};

export type GetRepoSourceBranchesOptions = {
  repoId: Repo['id'];
};

export const getRepoSourceBranches = ({
  repoId,
}: GetRepoSourceBranchesOptions): Promise<string[]> => {
  return axios.get(`/repos/${repoId}/source-branches`);
};

export type GetRepoMRCount = {
  repoId: Repo['id'];
  branch?: string;
};

export const getRepoMRCount = ({ repoId, branch }: GetRepoMRCount): Promise<{ count: number }> => {
  const params = { branch };
  return axios.get(`/repos/${repoId}/merge-request-count`, { params });
};

export type GetOpenBranchCount = {
  repoId: Repo['id'];
};

export const getOpenBranchCount = ({ repoId }: GetOpenBranchCount): Promise<{ count: number }> => {
  return axios.get(`/repos/${repoId}/open-branch-count`);
};

export type GetReleaseCount = {
  repoId: Repo['id'];
};

export const getReleaseCount = ({ repoId }: GetReleaseCount): Promise<{ count: number }> => {
  return axios.get(`/repos/${repoId}/release-count`);
};

export type GetRepoOptions = {
  repoId: Repo['id'];
};
export const getRepo = ({ repoId }: GetRepoOptions): Promise<Repo> => {
  return axios.get(`/repos/${repoId}`);
};

export const getRepoSafe = (repoId: string | undefined): Promise<Repo | undefined> => {
  if (repoId) {
    return axios.get(`/repos/${repoId}`);
  }

  return Promise.resolve(undefined);
};

export type DeleteRepoOptions = {
  repo: Repo;
};
export const deleteRepo = ({ repo }: DeleteRepoOptions): Promise<Repo> => {
  return axios.delete(`/repos/${repo.id}`);
};

export type UpdateRepoOptions = {
  repo: Repo;
};

export const updateRepo = ({
  repo: { id, name, uri, ciProvider, defaultBranch },
}: UpdateRepoOptions): Promise<Repo> => {
  return axios.put(`/repos/${id}`, { name, uri, ciProvider, defaultBranch });
};

export type UpdateRepoPipelineConfig = {
  data: {
    jobs: RepoPipelineJob[];
  };
  repoId: Repo['id'];
};

export const updateRepoPipelineConfig = ({
  data,
  repoId,
}: UpdateRepoPipelineConfig): Promise<RepoPipelineConfig> =>
  axios.post(`/repos/${repoId}/pipeline-config`, data);

export const getRepoPipelineConfig = ({
  repoId,
}: {
  repoId: Repo['id'];
}): Promise<RepoPipelineConfig> => {
  return axios.get(`/repos/${repoId}/pipeline-config`);
};

export const getRepoPipelineYaml = ({ repoId }: { repoId: Repo['id'] }): Promise<string> => {
  // axios response interceptor causes return value for .get() to be `AxiosResponse.data`.
  // So the second generic determines the return value of the function which is actually a string.
  return axios.get<any, string>(`/repos/${repoId}/pipeline-config/generate`);
};

export const pushRepoPipelineYaml = ({ repoId }: { repoId: Repo['id'] }): Promise<string> => {
  // axios response interceptor causes return value for .get() to be `AxiosResponse.data`.
  // So the second generic determines the return value of the function which is actually a string.
  return axios.post<any, string>(`/repos/${repoId}/pipeline-config/push`);
};

export type GetRepoApiKeysOptions = {
  repoId: Repo['id'];
};
export const getRepoApiKeys = ({ repoId }: GetRepoApiKeysOptions): Promise<RepoApiKey[]> => {
  return axios.get(`/repos/${repoId}/api-keys`);
};

export type FindOrCreateOrgApiKeysOptions = {
  orgId: Org['id'];
};

export const findOrCreateOrgApiKeys = ({
  orgId,
}: FindOrCreateOrgApiKeysOptions): Promise<OrgApiKey[]> => {
  return axios.get(`/orgs/${orgId}/api-keys`);
};

export type RotateOrgApiKeyOptions = {
  orgId: Org['id'];
};

export const rotateOrgApiKey = ({ orgId }: RotateOrgApiKeyOptions): Promise<OrgApiKey> => {
  return axios.post(`/orgs/${orgId}/api-keys`);
};

type RunProjectHILPipelineOptionsBody = {
  releaseId: Release['id'];
};

export type RunProjectHILPipelineOptions = {
  projectId: Project['id'];
  data: RunProjectHILPipelineOptionsBody;
};

export const runProjectHILPipeline = ({
  projectId,
  data,
}: RunProjectHILPipelineOptions): Promise<void> => {
  return axios.post(`/projects/${projectId}/hil/run`, data);
};

export type ConfigProjectHILOptionsBody = {
  recurrence: HIL_PIPELINE_RECURRENCE_FILTER;
  repoId?: Repo['id'];
  branch?: string;
  timezone?: string;
  hour?: number;
};

export type ConfigProjectHILOptions = {
  projectId: Project['id'];
  data: ConfigProjectHILOptionsBody;
};

export const configProjectHIL = ({ projectId, data }: ConfigProjectHILOptions): Promise<void> => {
  return axios.post(`/projects/${projectId}/hil/config`, data);
};

export const getProjectHilConfig = ({
  projectId,
}: GetProjectOptions): Promise<ConfigProjectHILOptionsBody> => {
  return axios.get(`/projects/${projectId}/hil/config`);
};

type CreateRepoBody = {
  name: string;
  uri: string;
  defaultBranch: string;
  ciProvider?: string;
  type?: REPO_TYPE;
};

type CreateRepoOptions = {
  orgId: Org['id'];
  projectId: Project['id'];
  data: CreateRepoBody;
};

export const createRepo = ({ data, orgId, projectId }: CreateRepoOptions): Promise<Repo> =>
  axios.post(`/orgs/${orgId}/projects/${projectId}/repos`, data);

// Return the "User" model for the currently authenticated user
export const getMyUser = (): Promise<User> => {
  return axios.get('/users/myself');
};

export type EditMyUserOptions = {
  data: {
    onboardingDismissed?: boolean;
  };
};

export const editMyUser = ({ data }: EditMyUserOptions): Promise<User> => {
  return axios.put('/users/myself', data);
};

export const getMyUserCiOauthTokens = (): Promise<UserCiOauthTokens> => {
  return axios.get('/users/myself/ci-oauth-tokens');
};

export type EditMyUserCiOAuthTokenOptions = {
  data: {
    code: string;
    ciProvider: CI_PROVIDERS;
  };
};

type EditMyUserCiOAuthTokenOutput = {
  [ciProvider in CI_PROVIDERS]?: CiOAuthToken['id'];
};

export const editMyUserCiOAuthTokens = ({
  data,
}: EditMyUserCiOAuthTokenOptions): Promise<EditMyUserCiOAuthTokenOutput> => {
  return axios.put('/users/myself/ci-oauth-tokens', data);
};

type GetRepoBranchesOptions = {
  ciProvider: CI_PROVIDERS;
  repo: string;
};

export const getRepoBranchesFromCiProvider = ({
  ciProvider,
  repo,
}: GetRepoBranchesOptions): Promise<string[]> => {
  return axios.get(`/users/myself/ci-providers/${ciProvider}/branches`, {
    params: {
      repo,
    },
  });
};

export type CiProviderRepo = {
  name: string;
  url: string;
};

type GetReposOptions = {
  ciProvider: CI_PROVIDERS;
};

export const getReposFromCiProvider = ({
  ciProvider,
}: GetReposOptions): Promise<CiProviderRepo[]> => {
  return axios.get(`/users/myself/ci-providers/${ciProvider}/repos`);
};

type ISOString = string;

export type GetMetricsOptions = {
  orgId?: Org['id'];
  projectId?: Project['id'];
  repoId?: Repo['id'];
  branch?: Repo['defaultBranch'];
  ciRunType?: 'test' | 'quality' | 'build';
  createdAt?: {
    begin?: ISOString;
    end?: ISOString;
  };
  name?: string;
};

export const getMetrics = ({
  ciRunType,
  branch,
  createdAt,
  projectId,
  orgId,
  repoId,
  name,
}: GetMetricsOptions): Promise<Metric[]> => {
  const params: Record<string, any> = { ciRunType, branch, projectId, orgId, repoId, name };
  if (createdAt) {
    if (createdAt.begin) {
      params['createdAt[begin]'] = createdAt.begin;
    }
    if (createdAt.end) {
      params['createdAt[end]'] = createdAt.end;
    }
  }
  return axios.get('/metrics', { params });
};

export type GetMetricOptions = {
  metricId: Metric['id'];
};

export const getMetric = ({ metricId }: GetMetricOptions): Promise<Metric> => {
  return axios.get(`/metrics/${metricId}`);
};

export type GetSymbolHistoryOptions = {
  repoId: Repo['id'];
  binaryName: string;
  symbolName: string;
  target: string;
  branch: string;
};

export const getSymbolHistory = ({
  repoId,
  binaryName,
  symbolName,
  target,
  branch,
}: GetSymbolHistoryOptions): Promise<SymbolDataPoint[]> => {
  const symbolBody = {
    repoId,
    binaryName,
    symbolName,
    target,
    branch,
  };
  return axios.post(`/metrics/symbol-history`, symbolBody);
};

export type GetJobDefsOptions = {
  includeTemplates?: boolean;
};

export const getJobDefs = ({ includeTemplates }: GetJobDefsOptions = {}): Promise<JobDef[]> => {
  return axios.get('/pipeline-config/jobdefs', { params: { includeTemplates } });
};

type JobDefBody = {
  name: string;
  stage: STAGE;
  substage?: string;
};

type CreateJobDefOptions = {
  data: JobDefBody;
};

export const createJobDef = ({ data }: CreateJobDefOptions): Promise<JobDef> =>
  axios.post(`/pipeline-config/jobdefs`, data);

type UpdateJobDefOptions = {
  data: JobDefBody;
  jobDefId: JobDef['id'];
};

export const updateJobDef = ({ data, jobDefId }: UpdateJobDefOptions): Promise<JobDef> => {
  return axios.put(`/pipeline-config/jobdefs/${jobDefId}`, data);
};

export const deleteJobDef = ({ jobDef }: { jobDef: JobDef }): Promise<JobDef> =>
  axios.delete(`/pipeline-config/jobdefs/${jobDef.id}`);

type JobTemplateBody = {
  jobDefId: JobDef['id'];
  template: string;
  provider: CI_PROVIDERS;
};

type UpdateJobTemplateOptions = {
  data: JobTemplateBody;
};

export const updateJobTemplate = ({ data }: UpdateJobTemplateOptions): Promise<JobTemplate> =>
  axios.put(`/pipeline-config/jobdefs/${data.jobDefId}/templates/${data.provider}`, {
    template: data.template,
  });

export type GetFleetDevicePublicKeyOptions = {
  deviceId: FleetDevice['deviceId'];
};

type FleetDeviceKey = {
  publicKey: string;
};

export const getFleetDevicePublicKey = ({
  deviceId,
}: GetFleetDevicePublicKeyOptions): Promise<FleetDeviceKey> => {
  // TODO: update this route to remove the 'fleet_id' piece as it is no longer needed
  return axios.get(`/fleets/fleet_id/device/${deviceId}/public-key`);
};

export type FleetInfo = {
  id: string;
  name: string;
};

export const getAvailableFleets = (): Promise<FleetInfo[]> => {
  return axios.get('/fleets/available-fleets');
};

export type HILCIRunsOptions = {
  repoId: Repo['id'];
  offset?: number;
  limit?: number;
  status?: string;
};

export const getHILCIRuns = (
  repoId: string,
  offset?: number,
  limit?: number,
  status?: string
): Promise<HILCIRunsQuery> => {
  const params = {
    offset,
    limit,
    status,
  };

  return axios.get(`/repos/${repoId}/hil-ci-runs`, { params });
};

export type GetFleetParams = {
  projectId: Project['id'];
};

export const getFleet = ({ projectId }: GetFleetParams): Promise<Fleet> => {
  return axios.get(`/projects/${projectId}/fleet`);
};

export type GetFleetsForProjectOptions = {
  projectId: Project['id'];
};

export const getFleetsForProject = ({
  projectId,
}: GetFleetsForProjectOptions): Promise<FleetWithDetail[]> => {
  return axios.get(`/projects/${projectId}/fleets`);
};


export type HILCIRunOptions = {
  repoId: Repo['id'];
  runId: HILCIRun['id'];
  updatedAfter?: string;
};

export const getHILCIRun = ({ repoId, runId, updatedAfter }: HILCIRunOptions): Promise<any> => {
  const params = new URLSearchParams();

  if (updatedAfter) {
    params.append('updatedAfter', updatedAfter);
  }

  return axios.get(`/repos/${repoId}/hil-ci-runs/${runId}`, { params });
};

type DeleteHILCIRunOptions = {
  repoId: Repo['id'];
  runId: HILCIRun['id'];
};

export const deleteHILCIRun = ({ repoId, runId }: DeleteHILCIRunOptions): Promise<void> => {
  return axios.delete(`/repos/${repoId}/hil-ci-runs/${runId}`);
};

export type HILArtifactURLOptions = {
  repoId: Repo['id'];
  runId: HILCIRun['id'];
  artifactId: string;
};

export const getHILArtifactURL = ({
  repoId,
  runId,
  artifactId,
}: HILArtifactURLOptions): Promise<any> => {
  return axios.get(
    `/repos/${repoId}/hil-ci-runs/${runId}/artifacts/${artifactId}/pre-signed-url-for-download`
  );
};

export type HILRunArtifactsURLOptions = {
  repoId: Repo['id'];
  runId: HILCIRun['id'];
};

export const getHILRunArtifactsURL = ({
  repoId,
  runId,
}: HILRunArtifactsURLOptions): Promise<any> => {
  return axios.get(`/repos/${repoId}/hil-ci-runs/${runId}/artifacts/zip`, { responseType: 'blob' });
};

export type HILRunCancelOptions = {
  repoId: Repo['id'];
  runId: HILCIRun['id'];
};

export const cancelHILRun = ({ repoId, runId }: HILRunCancelOptions): Promise<any> => {
  const cancelBody = {
    status: 'canceled',
  };
  return axios.patch(`/repos/${repoId}/hil-ci-runs/${runId}`, cancelBody);
};

type CheckoutSession = {
  url: string;
};
type GetCheckoutSessionBody = {
  returnUrl: string;
};

type GetCheckoutSessionOptions = {
  data: GetCheckoutSessionBody;
};

export const getCheckoutSession = ({
  data,
}: GetCheckoutSessionOptions): Promise<CheckoutSession> => {
  return axios.post('/subscriptions/account-session', data);
};

type GetActiveUsersBody = {
  startDate: string;
  endDate: string;
};

export const getActiveUsers = (data: GetActiveUsersBody): Promise<ActiveUsersData> => {
  return axios.post(`/analytics/activity`, data);
};

export const moveFleetDevice = (fleetDeviceId: string, projectId: string | null): Promise<void> => {
  return axios.post(`/fleet-devices/${fleetDeviceId}/move`, { projectId });
};

type UpdateDeviceNameOptions = {
  deviceId: string;
  data: {
    name: string;
  };
};

export const updateDeviceName = ({ deviceId, data }: UpdateDeviceNameOptions): Promise<void> =>
  axios.patch(`/fleet-devices/${deviceId}/rename`, data);

export type GetTemplatesForOrgOptions = {
  orgId: Org['id'];
};
export const getTemplatesForOrg = ({ orgId }: GetTemplatesForOrgOptions): Promise<Template[]> => {
  return axios.get(`/orgs/${orgId}/templates`);
};

type CreateTemplateBody = {
  uri: string;
  accessToken?: string;
};

type CreateTemplateOptions = {
  orgId: Org['id'];
  data: CreateTemplateBody;
};

export const createTemplate = ({ data, orgId }: CreateTemplateOptions): Promise<void> =>
  axios.post(`/orgs/${orgId}/templates`, data);

type DeleteTemplateFromOrgOptions = {
  org: Org;
  template: Template;
};

export const deleteTemplateFromOrg = ({
  org,
  template,
}: DeleteTemplateFromOrgOptions): Promise<void> =>
  axios.delete(`/orgs/${org.id}/templates/${template.id}`);

type UpdateTemplateBody = Partial<CreateTemplateBody>;

export const updateTemplate = ({
  data,
  orgId,
  templateId,
}: {
  data: UpdateTemplateBody;
  orgId: Org['id'];
  templateId: Template['id'];
}): Promise<void> => axios.patch(`/orgs/${orgId}/templates/${templateId}`, data);
