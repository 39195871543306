import { Combobox } from 'evergreen-ui';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

import { FieldWrapper } from '@/components/Form/FieldWrapper';

import { Spinner } from '../Spinner';

export type SearchDropdownProps<Entry> = {
  items: Array<Entry>;
  label?: string;
  testId?: string;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  initialSelectedItem?: Entry;
  error?: FieldError | undefined;
  selectedItem?: Entry | null;
  onChange: (value: Entry) => void;
  // need to use 'any' because of evergreen forces it to be string but actually it's SearchDropdownBaseItem
  itemsFilter?: (items: any[], input: string) => any[];
};

export type SearchDropdownBaseItem = {
  name: string;
};

export const SearchDropdown = <Entry extends SearchDropdownBaseItem>({
  items,
  label,
  testId,
  placeholder,
  className,
  disabled,
  loading,
  initialSelectedItem,
  selectedItem,
  error,
  onChange,
  itemsFilter,
}: SearchDropdownProps<Entry>): JSX.Element => {
  const [width, setWidth] = React.useState(240);
  const comboRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (comboRef.current) {
      const rect = comboRef.current.getBoundingClientRect();
      setWidth(rect.width);
    }
  }, []);

  return (
    <div ref={comboRef} className={className}>
      <FieldWrapper label={label} error={error}>
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <Combobox
            openOnFocus
            data-testid={testId}
            label={label}
            placeholder={placeholder}
            items={items}
            itemToString={(item) => item?.name || ''}
            width={width}
            height={40}
            initialSelectedItem={initialSelectedItem}
            selectedItem={selectedItem}
            disabled={disabled}
            // eslint-disable and ts-ignore are required because prop-types is not correctly defined in evergreen-ui library.
            // eslint-disable-next-line
            // @ts-ignore
            autocompleteProps={{
              itemsFilter,
              popoverMinWidth: width,
            }}
            onChange={onChange}
          />
        )}
      </FieldWrapper>
    </div>
  );
};
